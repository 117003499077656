import React from 'react'
import { TITLE, NAME } from '../../../content'
import * as styles from './SiteTitle.module.scss'

const SiteTitle = ({ tagLevel = 1, tag, className }) => {
  const Heading = !tag ? `h${tagLevel}` : tag
  const Secondary = !tag ? `h${tagLevel + 1}` : tag

  return (
    <div className={`${styles.hgroup}  ${className ? className : ''}`}>
      <hgroup>
        <Heading className={styles.h1}>{NAME}</Heading>
        <Secondary className={styles.h2}>{TITLE}</Secondary>
      </hgroup>
    </div>
  )
}

export default SiteTitle
