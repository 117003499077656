/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from 'react'
import PropTypes from 'prop-types'
import 'normalize.css'
import '../styles/index.scss'
import BookCTA from '../components/BookCTA/BookCTA'

import Header from '../components/header'
import Footer from '../components/Blocks/Footer/Footer'

const Layout = ({ children }) => {
  return (
    <>
      <Header />
      <main>{children}</main>

      <BookCTA />
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
