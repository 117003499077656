import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import { Layout } from '.'
import SiteTitle from '../components/Blocks/SiteTitle/SiteTitle'
import * as styles from '../pages/presentation/index.module.scss'

const LayoutWithImage = ({ children }) => {
  const { image } = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "coral.png" }) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SiteTitle tag="span" />

      <div className="wrapper">
        <BackgroundImage
          className={styles.Presentation}
          Tag="section"
          fluid={image?.sharp?.fluid}
          style={{ backgroundSize: 'contain', zIndex: -1 }}
          id="presentation"
        />
        {children}
      </div>
    </Layout>
  )
}

export default LayoutWithImage
