import React from 'react'
import * as ButtonStyles from '../Button/Button.module.scss'
import * as styles from './Link.module.scss'

const Link = ({ href, className, children, ...rest }) => (
  <a
    {...rest}
    className={`${ButtonStyles.Button} ${styles.Link} ${
      className ? className : ''
    }`}
    href={href}
  >
    {children}
  </a>
)

export default Link
