import React from 'react'
import { Link } from 'gatsby'
import { CONTENT } from '../../../content'
import SiteTitle from '../SiteTitle/SiteTitle'
import * as styles from './Footer.module.scss'

const Footer = () => (
  <>
    <footer className={`${styles.footer} wrapper`}>
      <div className={styles.logo}>
        <SiteTitle tagLevel={2} className={styles.footer_site_title} />
      </div>
      {CONTENT.PAGE_NAV.items.map(({ title, content }, index) => (
        <div className={styles.item} key={index}>
          <header>
            <h4>{title}</h4>
          </header>
          <ul>
            {content.map((c, i) => (
              <li key={`${c.title}-${i}`} className={styles.list_item}>
                <Link title={c.title} to={c.to}>
                  {c.title}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </footer>
  </>
)

export default Footer
