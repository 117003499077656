import React, { useEffect, useRef, useState } from 'react'
import * as styles from './Header/Header.module.scss'
import Nav from './Nav'
import Menu from './Menu/Menu'

const Header = () => {
  const [shadow, setShadow] = useState(false)
  const [isMobile, setIsMobile] = useState(false)
  const scrollRef = useRef(null)
  const monitorScroll = () => {
    if (window.pageYOffset > 60) {
      setShadow(true)
    } else {
      setShadow(false)
    }
  }

  useEffect(() => {
    setIsMobile(/mobi/i.test(navigator.userAgent))
  }, [])

  useEffect(() => {
    if (scrollRef.current) {
      document.addEventListener('scroll', monitorScroll)
    }

    return () => {
      if (scrollRef.current) {
        return document.removeEventListener('scroll', monitorScroll)
      }
    }
  }, [scrollRef.current])
  return (
    <header
      className={`${styles.header} ${shadow ? styles.shadowed : ''}`}
      ref={scrollRef}
    >
      {!isMobile ? <Nav /> : <Menu />}
    </header>
  )
}

export default Header
