import React, { useState } from 'react'
import MenuToggle from './MenuToggle'
import MenuDrawer from './MenuDrawer'
import * as styles from './Menu.module.scss'
import Nav from '../Nav'

const Menu = () => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleMenu = () => setIsOpen(!isOpen)

  return (
    <div className={styles.menu}>
      <MenuToggle isActive={isOpen} onClick={toggleMenu}>
        Menu
      </MenuToggle>
      <MenuDrawer onClick={toggleMenu} isOpen={isOpen}>
        <Nav />
      </MenuDrawer>
    </div>
  )
}

export default Menu
