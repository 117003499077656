export const NAME = 'Emilie PFISTER.'
export const TITLE = 'Psychologue clinicienne - Psychothérapeute'
export const INTRO = [
  'Psychologue clinicienne et psychothérapeute depuis 15 ans, j’accueille à mon cabinet à Dieulouard (54380) toute personne souhaitant engager un cheminement personnel, développer ses ressources pour accéder à plus de sérénité et d’apaisement psychique.',
  'Ma pratique clinique intégrative s’inscrit dans la collaboration entre nos expertises conjointes :',
  'Celle que me confèrent mes connaissances et expériences en tant que professionnelle aguerrie et celle qui est la vôtre en tant que spécialiste de votre histoire.',
  'Le psychologue vous accompagne vers d’autres possibles mais <span class="bold">vous</span> restez l’expert incontesté et incontestable de votre parcours de vie.',
]

export const CONTENT = {
  PRESENTATION: {
    PSYCHOLOGIST: {
      title: 'Qu’est-ce qu’un psychologue clinicien ?',
      content: [
        'Le psychologue est un spécialiste de la psychologie et plus particulièrement du fonctionnement psychique, des comportements, des pensées et des émotions humaines.',
        'Un psychologue est dit clinicien lorsqu’il est spécialisé en psychopathologie c’est-à-dire l’étude des troubles psychiques et leurs causes.',
        'Il dispose de différentes approches et outils pour favoriser l’évolution des personnes qui le consultent vers un mieux être dans le respect du code de déontologie qui guide sa pratique.',
      ],
    },
    PSYCHOTHERAPIST: {
      title: 'Qu’est-ce qu’un psychothérapeute ?',
      content: [
        'Le psychothérapeute est un professionnel qui pratique des psychothérapies. Autrefois non réglementé, le titre est désormais soumis à vérification et validation par l’Agence Régionale de Santé.',
        'Le professionnel doit attester de connaissances théoriques et d’une pratique conséquente dans le domaine de la psychopathologie clinique.',
        'Lorsque le professionnel psychothérapeute est également psychologue clinicien, son titre est enregistré sous le même numéro ADELI.',
        'Mes titres de psychologue clinicienne et de psychothérapeute sont authentifiés et enregistrés auprès de l’<strong>Agence Régionale de Santé de Nancy</strong> sous le numéro ADELI : 549303477',
        '(Le répertoire ADELI est un système d’information national répertoriant les professionnels autorisés à faire usage du titre de psychologue.)',
      ],
    },
  },
  CONSULTATION: {
    WHY: {
      title: 'Pourquoi consulter ?',
      content: [
        'Il n’existe pas à proprement parlé de <span class="italic">bonnes</span> ou <span class="italic">mauvaises</span> raisons ou de <span class="italic">bons</span> ou <span class="italic">mauvais</span> moments pour consulter un psychologue.',
        'Le désir de voir la situation qui vous fait souffrir ou vous pose problème évoluer vers plus d’apaisement se suffit à lui-même pour motiver une consultation auprès d’un professionnel. Si une demande émerge alors il s’agit du bon moment pour vous !',
        'Cette démarche de sollicitation d’aide est souvent une étape compliquée à mettre en œuvre. ',
        'Elle témoigne pourtant d’un cheminement personnel qui a déjà abouti. Celui de votre prise de conscience que quelque chose ne va plus et doit prendre fin, de votre volonté d’agir différemment et donc de vos ressources en termes de potentialité de changements.',
        'Il est important pour le travail qui va s’engager et pour votre confort personnel que vous perceviez les échanges, le cadre de consultation et la personne de votre thérapeute dans une dynamique relationnelle authentique et apaisée.',
        'Rencontrer un psychologue, c’est décider de prendre soin de soi psychiquement et ce processus bienveillant de soi envers soi débute dès le premier entretien par cette attention que vous porterez sur vos ressentis dans la relation qui se crée alors avec votre thérapeute.',
        "Pour le dire de façon plus triviale, « Si vous ne sentez pas votre praticien », quelqu'en soient les raisons et ce même si vous ne les identifiez pas rationnellement, il est primordial que vous vous autorisiez à en consulter un autre !",
      ],
    },
    AUDIENCE: {
      title: 'Public accueilli',
      intro:
        'J’accueille à mon cabinet toute personne se ressentant en souffrance, détresse ou difficulté psychologique et qui souhaite engager un cheminement personnel de mise en mots et en sens de ses difficultés.',
      content: [
        {
          text: 'Enfants',
          title: 'Vers la page public accueilli > enfants',
          to: '/consultation/public-accueilli/enfants',
        },
        {
          text: 'Adolescents',
          title: 'Vers la page public accueilli > adolescents',
          to: '/consultation/public-accueilli/adolescents',
        },
        {
          text: 'Adultes',
          title: 'Vers la page public accueilli > adultes',
          to: '/consultation/public-accueilli/adultes',
        },
        {
          text: 'Professionnels',
          title: 'Vers la page public accueilli > professionnels',
          to: '/consultation/public-accueilli/professionnels',
        },
      ],
      meta: {
        description: `Que l'on soit enfant, adulte, adolescent ou adulte l'on peut avoir besoin d'un accompagnement psychologique personnalisé délivré par un psychologue`,
        title: 'Public accueilli',
      },
      ADULTS: {
        title: 'En clinique d’accompagnement de l’adulte :',
        meta: {
          title: `Accompagnement de l'adulte`,
          description: `Les aléas de la vie d'un adulte peuvent nécessiter un accompagnement émotionnel personnalisé délivré par un psychologue clinicien`,
        },
        content: [
          'Vous avez des difficultés personnelles (manque de confiance en soi, d’estime de soi, angoisses, anxiété, stress).',
          'Vous vivez une épreuve de vie difficile à gérer émotionnellement (deuil, licenciement, séparation, conflit intrafamilial , professionnel).',
          'Vous êtes en difficulté dans votre couple, avez le sentiment de ne plus trouver votre place ou ne pas être à la place que vous aimeriez.',
          'Vous vous sentez parfois démuni dans votre rôle de parent ( un des plus complexe qui soi !) et avez besoin d’un espace de soutien et de guidance dans vos relations avec vos enfants qui se compliquent…',
          'Vous avez vécu un ou plusieurs traumatismes, uniques, ou répétés, récemment ou par le passé et vous en souffrez toujours.',
          'Vous êtes en difficulté dans vos relations sociales personnelles et / ou professionnelles.',
          'Vos émotions vous débordent parfois, votre timidité, votre colère, votre impulsivité vous empêchent dans vos projets de vie.',
          'Vous souffrez de symptômes diffus dont vous n’avez pas identifier ni les causes probables, ni les déclencheurs (insomnies, perte d’appétit).',
          'Vous souhaitez mettre du sens sur votre parcours de vie, en appréhender la complexité et les mécanismes afin de mieux vous comprendre dans vos réactions et accéder à plus de bienveillance et de sérénité à l’égard de votre chemin de vie.',
        ],
      },
      CHILDREN: {
        meta: {
          title: `Accompagnement de l'enfant`,
          description: `Je peux vous aider et vous accompagner dans la gestion des conflits avec votre enfant et faire en sorte d'apaiser votre relation`,
        },
        title: 'En clinique d’accompagnement de l’enfant :',
        content: [
          'Votre enfant vous inquiète dans son attitude, ses comportements et vous ressentez le besoin d’en échanger avec un professionnel du soin psychique.',
          'Votre enfant présente depuis quelques temps des symptômes que vous ne parvenez pas à faire cesser pour son mieux être (troubles de l’alimentation, du sommeil, de l’endormissement, peurs, angoisses, régression dans son développement, énurésie etc.).',
          'Votre enfant vous apparait préoccupé, triste ou au contraire se montre subitement agité et difficilement canalisable.',
          'Votre enfant est en difficulté dans ses relations sociales…',
        ],
      },
      TEENAGERS: {
        meta: {
          title: `Accompagnement de l'adolescent`,
          description: `Je peux vous aider dans la gestion des conflits avec votre adolescent et à retrouver un foyer serein`,
        },
        title: 'En clinique d’accompagnement de l’adolescent :',
        content: [
          'Votre ado se montre distant, virulent à votre égard parfois sans raison apparente, la situation entre vous se crispe.',
          'Il désinvestit subitement sa scolarité, vous peinez à maintenir un cadre contenant au quotidien.',
          'Votre adolescent vit une situation difficile  suite à un événement repéré ou non ( rupture, deuil, maladie, harcèlement scolaire).',
          'La communication entre vous et votre enfant est rompue et vous avez le sentiment d’avoir tout essayé pour rétablir un lien et une communication sereine et apaisée entre vous.',
        ],
      },
      PROFESSIONALS: {
        meta: {
          title: `Accompagnement de l'adolescent`,
          description: `Je peux vous accompagner à faire face à des enjeux professionnels importants pour votre carrière et pour votre bien être`,
        },
        title: 'En clinique du soutien  professionnel :',
        content: [
          'Vous êtes à l’aube d’un entretien important pour vos projets et vous souhaitez travailler sur l’entretien motivationnel, la valorisation de vos compétences et ressources , échanger sur les techniques de gestion du stress contextuel ou tout simplement développer votre potentiel.',
          'Vous êtes professionnel de la relation d’aide ( psychologue, éducateur) et souhaitez vous investir dans un espace clinique pour échanger en confidentialité sur des situations qui vous mettent en difficulté ou résonnent particulièrement avec votre parcours personnel.',
          'Vous êtes à la recherche d’un superviseur pour votre équipe de professionnels, j’interviens notamment pour l’analyse des pratiques professionnelles dans mon domaine d’expertise qu’est la protection de l’enfance.',
        ],
      },
    },
  },
  MEETING: {
    GENERAL: {
      title: 'Déroulement d’une séance ',
      content: [
        '<strong>La première séance est essentielle</strong> car elle me permet de cerner votre demande en première intention. De votre côté, elle vous permet de saisir si l’espace thérapeutique que je vous propose vous correspond.',
        'Lors de cet entretien, nous évoquons les motifs qui vous ont menés à cette démarche de consultation.',
        'Nos échanges m’amèneront à établir si vos attentes relèvent bien de mon domaine de compétences ou s’il m’apparait plus opportun pour vous que je vous réoriente vers un confrère.',
        'En fonction de la singularité de chaque situation, plusieurs séances (rarement plus de 2 ou 3) pourront être nécessaires pour qu’ensemble nous parvenions à cibler la voie qui sera la plus pertinente pour vous.',
        'Cette première rencontre a également pour vocation de constituer un espace pour répondre à vos questions, craintes éventuelles etc…',
        'Nous aborderons  le déroulement des séances à venir, leur rythme,  toujours dans la perspective d’une construction partagée et personnalisée de l’accompagnement envisagé ( entretien de soutien, accompagnement centré sur la personne, prise en charge psychothérapique spécifique).',
        'La séance est réfléchie sur une heure. La durée de notre entretien s’établit sur 45/50 min et un temps de reprise pour moi post séance (notes) favorise une continuité pour une prise en charge ajustée entres les rendez-vous.',
      ],
    },
    CHILDREN: {
      title: 'En ce qui concerne l’accompagnement psychologique des enfants :',
      content: [
        'Un premier entretien en présence des parents est souhaitable, afin de comprendre l’origine supposée, partagée ou non avec l’autre parent, avec l’enfant de la difficulté constatée.',
        'La configuration des séances alors établie pourra être variable alternant des temps d’échanges auprès de chacun des parents pour recueillir leurs points de vue et observations puis des temps de prises en charge auprès de l’enfant seul ou accompagné de son parent.',
        'En fonction de l’âge de l’enfant la durée de la séance  auprès de lui pourra être réduite au profit de ces moments d’accompagnement et de guidance parentale.',
        'La psychothérapie d’enfants prend une forme spécifique qui s’adapte continuellement en fonction de leurs capacités d’attention et d’élaboration verbale.',
        'Aussi la psychothérapie de l’enfant réinterroge continuellement la créativité du psychologue qui doit ajuster son accompagnement par l’utilisation de supports facilitants comme le jeu ou le dessin, l’arbre de vie etc…',
        'Le thérapeute vient à la rencontre de son petit patient là où il est et là où il en est…',
        'En ce qui concerne la prise en charge psychologique des adolescents et en fonction des besoins, le suivi psychothérapique sera davantage dédié à leur rencontre spécifique.',
        'Il s’agit d’offrir un espace de parole neutre qui leur est consacré (ce qui est particulièrement important à cette étape fondamentale de la construction identitaire) et qui favorisera l’élaboration d’une alliance thérapeutique de qualité.',
      ],
    },
  },
  FEES: {
    title: 'Honoraires',
    meta: {
      title: 'Honoraires',
      description:
        'Honoraires des séances pour mes patients enfant, adolescents, adultes et professionnels',
    },
    content: [
      'Accompagnement individuel (45 à 50 min) : 60 euros (adulte, adolescent, enfant)',
      'Accompagnement entretien de couple, guidance parentale conjointe (1 heure) : 80 euros',
      'Accompagnement entretien motivationnel professionnel : 80 euros',
      'Supervision individuelle : 60 euros',
      'Analyse des pratiques professionnelles en institution : sur devis',
    ],
    disclaimer: [
      'Les consultations devront être réglées à chaque fin de séance. Le règlement pourra se faire par chèque ou en espèces.',
      'Les consultations ne sont pas remboursées par la sécurité sociale. En revanche, certaines mutuelles prennent en charge ( partiellement ou totalement, selon les modalités de votre contrat) les séances chez un psychologue clinicien psychothérapeute (certifié <abbr title="Agence Régionale de Santé">ARS</abbr>) ce qui est mon cas.',
      'Une note d’honoraire vous sera fournie à votre demande à l’issue de chacune des séances, afin que vous puissiez bénéficier des remboursements prévus dans votre contrat le cas échéant.',
    ],
  },
  CONTACT: {
    meta: {
      title: 'Contact',
      description:
        "Voici les informations permettant de ma contacter et de me joindre ainsi qu'une carte pour situer mon cabinet",
    },
    GENERAL: {
      title: 'Me contacter',
      content: [
        'Les consultations se tiennent sur rendez-vous UNIQUEMENT.',
        'Le cabinet se situe sur la commune de Dieulouard (54380) au 13 rue du stade.',
        'Pour toute information complémentaire, vous pouvez me contacter par mail : <a href="mailto:emiliepfister016@gmail.com">emiliepfister016@gmail.com</a>',
      ],
    },
    APPOINTMENTS: {
      title: 'Pour prendre rendez-vous je suis joignable :',
      content: [
        'Par téléphone de 9h à 17h30 du lundi au vendredi au <a href="tel:07 80 97 92 98">07 80 97 92 98</a>',
        'Pendant les temps de consultations, n’hésitez pas à laisser votre message sur le répondeur téléphonique, je vous recontacterai dès que possible.',
        'Vous pouvez également prendre rendez-vous sur <a href="https://doctolib.fr/psychologue/dieulouard/emilie-pfister" rel="noopener noreferrer external me" target="_blank">Doctolib.fr</a>, des plages horaires en ligne y sont dédiées.',
      ],
    },
  },
  PAGE_NAV: {
    title: 'Un accompagnement et une approche personnalisés',
    subtitle:
      "Enfants, adolescents, adultes, professionnels, je vous apporterai un soutien lors d'étapes difficiles de la vie engendrant une souffrance et une perte d'estime de soi et vous aiderai à comprendre vos difficultés, à les dénouer et à trouver des solutions adaptées.",
    items: [
      {
        title: 'Qui suis-je',
        content: [
          {
            title: 'Psychologue clinicien - Psychothérapeute',
            to: '/psychologue-clinicien-psychotherapeute',
          },
          { title: 'Le cabinet', to: '/le-cabinet' },
        ],
        icon: 'LightBulb',
        to: '/presentation',
      },
      {
        title: 'Consultations',
        content: [
          {
            title: 'Pourquoi consulter ?',
            to: '/consultation',
          },
          {
            title: 'Public accueilli',
            to: '/consultation/public-accueilli',
          },
          {
            title: `Déroulement d'une séance`,
            to: 'consultation/deroulement-seance',
          },
        ],
        icon: 'Psychology',
        to: '/consultation',
      },
      {
        title: 'Honoraires',
        content: [{ title: 'Mes différentes prestations', to: '/honoraires' }],
        icon: 'Payments',
        to: '/honoraires',
      },
      {
        title: 'Contact',
        content: [{ title: 'Me contacter', to: '/contact' }],
        icon: 'Location',
        to: '/contact',
      },
    ],
  },
  WHOAMI: {
    title: 'Qui suis-je ?',
    content: [
      '<strong>Psychologue clinicienne et psychothérapeute</strong>, j’ai débuté ma carrière professionnelle il y a quinze ans maintenant auprès d’adultes bénéficiant de soins psychiatriques prolongés.',
      'Puis j’ai fait le choix en 2010 de m’orienter vers la richesse des missions de protection de l’enfance en danger en intégrant l’<span class="italic">AEMO</span> (Action Éducative en Milieu Ouvert).',
      'L’accompagnement à la parentalité en difficulté constitue donc l’un de mes domaines d’expertise.',
      'Mes expériences professionnelles m’ont permis d’acquérir et parfaire une clinique diversifiée tant du côté des problématiques que du public pris en charge (adultes/ enfants/ adolescents ).',
      '</br>',
      'Ma démarche clinique s’incarne dans une perspective d’accompagnement personnalisé et respectueux des valeurs déontologiques qui me guident. Ainsi toute mon attention se porte sur l’adaptation et l’ajustement constant à mes patients  dans le respect de la singularité de chacun et dans l’élaboration conjointe autour de votre demande.',
      'De formation universitaire (master 2 professionnel) d’orientation psychodynamique j’ai développé au fil des années mes compétences et connaissances autour d’autres approches.',
      'Ma pratique se veut donc résolument intégrative c’est-à-dire qu’elle associe différentes approches afin de répondre au mieux aux besoins de mes patients. C’est dans ce souci constant que je m’engage dans diverses formations qui me permettent d’ouvrir des perspectives nouvelles , d’élargir mon regard clinique mais aussi diversifier mes outils thérapeutiques…',
      'Je suis notamment formée à l’approche systémique, aux thérapies brèves de l’école de Palo Alto et orientée solutions. J’intègre à ma clinique ce double regard porté à la fois sur le patient dans son individualité mais aussi sur sa place et son rôle dans l’ensemble des relations qu’il entretient avec son environnement.',
      '</br>',
      'J’accompagne également les personnes victimes de psycho-traumatismes , trauma  unique (agression accident) ou répétés (vécu de maltraitance, négligence durant l’enfance) à travers deux approches psychothérapeutiques différentes : le <span class="italic">RITMO</span> (Retraitement traumatique par les mouvements oculaires ) et L’ICV (Intégration des cycles de vie).',
      'Ces deux approches psychothérapiques reposent sur des bases du fonctionnement neurobiologique.',
      'Le <abbr title="retraitement de l’information traumatique  par les mouvements oculaires" class="italic">RITMO</abbr> (retraitement de l’information traumatique  par les mouvements oculaires) est une méthode assimilée à l’<span class="italic">EMDR</span> qui permet l’intégration de l’information traumatique restée comme gelée et figée par l’effroi du trauma.',
      'Les stimulations bilatérales alternées vont favoriser cette « digestion psychique » de l’information traumatique qui pourra alors être pensée sans la réactivation émotionnelle invalidante qui y était associée.',
      'L’<abbr title="Intégration des cycles de vie" class="italic">ICV</abbr> (Intégration des cycles de vie) permet dans le même objectif que le <span class="italic">RITMO</span> d’agir sur l’assimilation et l’intégration psychique du souvenir traumatique qui se vide peu à peu de son contenu émotionnel délétère. Cette fois le support travaillé s’appelle la ligne du temps.',
      'Séance après séance, le souvenir est retraité de façon opérante et permet au patient  de retrouver un confort de vie , une visibilité et une confiance en ses propres ressources personnelles.',
    ],
  },
  OFFICE: {
    title: 'Le cabinet',
    content: [
      'Le cabinet où je pratique est situé sur la commune de Dieulouard (54380).',
      '<a class="link" href="https://www.google.com/maps/place/13+Rue+du+Stade,+54380+Dieulouard/@48.8399136,6.0638741,17z/data=!3m1!4b1!4m5!3m4!1s0x4794b902db501775:0xcac710dff334770!8m2!3d48.8399136!4d6.0660628" rel="noopener noreferrer external" target="_blank">Vous pouvez vous y rendre facilement en voiture</a> ou par le train depuis Nancy (54000) situé à 25 kms environ.',
      `Des photos sont à venir, vous trouverez d'autres renseignements sur la page <span class="italic">contact</span>`,
    ],
  },
  MARKER: {
    title: 'Emilie PFISTER',
    content: [
      '13 rue du stade, 54380 Dieulouard',
      'Consultations de 9h à 17h30 du Lundi au vendredi',
      '<a href="tel:0780979298">07 80 97 92 98</a>',
    ],
  },
}

export const APPOINTMENT_BOOK = 'Prendre rendez-vous'
export const CONTACT_INFOS = {
  PHONE: '07 80 97 92 98',
  EMAIL: 'emiliepfister016@gmail.com',
  DOCTOLIB: 'https://doctolib.fr/psychologue/dieulouard/emilie-pfister',
}
