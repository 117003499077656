import React, { useEffect, useRef } from 'react'
import * as styles from './MenuToggle.module.scss'

const MenuToggle = ({ onClick, isActive, focus = false, children }) => {
  const buttonRef = useRef(null)

  useEffect(() => {
    if (focus) {
      buttonRef.current.focus()
    }
  }, [buttonRef])

  function handleEscapeKeyPress(event) {
    if (isActive && event.key === 'Escape') {
      onClick()
    }
  }

  return (
    <div
      className={`${styles.menu_toggle} ${
        isActive ? styles.menu_toggle__active : ''
      }`}
      title={`${!isActive ? 'Ouvrir' : 'Fermer'} le menu`}
    >
      <button
        onClick={onClick}
        ref={buttonRef}
        className={styles.menu_toggle__button}
        onKeyDown={handleEscapeKeyPress}
      >
        <span className={styles.menu__bar}></span>
        <span className={styles.menu__bar}></span>
        <span className={styles.menu__bar}></span>
      </button>

      {children ? (
        <span
          onClick={() => {
            onClick()
            buttonRef.current.focus()
          }}
          className={styles.menu_toggle__children}
        >
          {children}
        </span>
      ) : null}
    </div>
  )
}

export default MenuToggle
