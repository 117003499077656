import React from 'react'
import * as styles from './index.module.scss'
import { Link } from 'gatsby'
import ChevronRight from '../Icons/ChevronRight'

const Nav = () => (
  <nav className={styles.nav}>
    <div className={`${styles.wrapper} wrapper`}>
      <ul className={styles.ul}>
        <li>
          <Link
            className={styles.link}
            to="/"
            activeClassName={styles.link_active}
          >
            Accueil
          </Link>
        </li>
        <li className={styles.has_sublist}>
          <Link
            className={styles.link}
            to="/presentation"
            activeClassName={styles.link_active}
            partiallyActive={true}
          >
            Présentation
            <ChevronRight />
          </Link>

          <ul className={styles.sublist}>
            <li>
              <Link
                className={styles.link}
                to="/presentation"
                activeClassName={styles.link_active}
              >
                Qui suis-je ?
              </Link>
            </li>
            <li>
              <Link
                to="/presentation/psychologue-clinicien-psychoterapeute"
                activeClassName={styles.link_active}
              >
                Psychologue Clinicien - Psychothérapeute
              </Link>
            </li>
            <li>
              <Link
                partiallyActive={true}
                activeClassName={styles.link_active}
                to="/presentation/le-cabinet"
              >
                Le cabinet
              </Link>
            </li>
          </ul>
        </li>

        <li className={styles.has_sublist}>
          <Link
            className={styles.link}
            to="/consultation"
            activeClassName={styles.link_active}
            partiallyActive={true}
          >
            Consultation
            <ChevronRight />
          </Link>

          <ul className={styles.sublist}>
            <li>
              <Link
                className={styles.link}
                to="/consultation"
                activeClassName={styles.link_active}
              >
                Pourquoi consulter ?
              </Link>
            </li>
            <li>
              <Link
                className={styles.link}
                to="/consultation/deroulement-seance"
                activeClassName={styles.link_active}
              >
                Déroulement d'une séance
              </Link>
            </li>
            <li className={`${styles.sublist_item} ${styles.has_sublist}`}>
              <Link
                className={styles.link}
                to="/consultation/public-accueilli/"
                activeClassName={styles.link_active}
                partiallyActive={true}
              >
                Public accueilli
                <ChevronRight />
              </Link>

              <ul className={styles.sublist}>
                <li>
                  <Link
                    className={styles.link}
                    to="/consultation/public-accueilli/enfants"
                    activeClassName={styles.link_active}
                  >
                    Enfants
                  </Link>
                </li>
                <li>
                  <Link
                    className={styles.link}
                    to="/consultation/public-accueilli/adultes"
                    activeClassName={styles.link_active}
                  >
                    Adultes
                  </Link>
                </li>
                <li>
                  <Link
                    className={styles.link}
                    to="/consultation/public-accueilli/adolescents"
                    activeClassName={styles.link_active}
                  >
                    Adolescents
                  </Link>
                </li>
                <li>
                  <Link
                    className={styles.link}
                    to="/consultation/public-accueilli/professionnels"
                    activeClassName={styles.link_active}
                  >
                    Professionnels
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </li>

        <li>
          <Link
            className={styles.link}
            to="/honoraires"
            activeClassName={styles.link_active}
          >
            Honoraires
          </Link>
        </li>
        <li>
          <Link
            className={styles.link}
            to="/contact"
            activeClassName={styles.link_active}
          >
            Contact
          </Link>
        </li>
      </ul>
    </div>
  </nav>
)

export default Nav
