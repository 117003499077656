import React from 'react'
import * as styles from './MenuDrawer.module.scss'

const MenuDrawer = ({ isOpen, children }) => (
  <section
    className={`${styles.menu_drawer} ${
      isOpen ? styles.menu_drawer__visible : ''
    }`}
  >
    {children}
  </section>
)

export default MenuDrawer
